import { interpolateRgb, rgb, ScaleLinear, scaleLinear } from "d3";
import { Grid } from "../../types/Grid";

export const waveColorScale = scaleLinear<string, number>()
  .domain([0, 0.00001, 0.5, 0.75, 1, 2]) // Real-world domain
  .range([
    "rgba(0, 0, 0, 0)",
    "rgba(0, 204, 0, 0.1)",
    "rgba(135, 217, 0, 0.3)",
    "rgba(230, 172, 0, 0.6)",
    "rgba(255, 51, 0, 0.9)",
    "rgba(255, 0, 0, 1)",
  ])
  .interpolate(interpolateRgb)
  .clamp(true);

// Function to convert Float32Array to ImageBitmap
export async function createImageBitmapFromGridData(
  grid: Grid,
  colorScale: ScaleLinear<string, string>
): Promise<ImageBitmap> {
  const pixelData = new Uint8ClampedArray(grid.cols * grid.rows * 4); // For RGBA

  // Process the grid data into RGBA format
  for (let i = 0; i < grid.cols * grid.rows; i++) {
    const value = grid.data[i]; // Your float value
    const color = colorScale(value)!;
    const clrs = rgb(color);

    // Set RGBA values
    pixelData[i * 4] = value > 0 ? clrs.r : 0;
    pixelData[i * 4 + 1] = clrs.g;
    pixelData[i * 4 + 2] = clrs.b;
    pixelData[i * 4 + 3] = Math.round(clrs.opacity * 255); // Alpha (fully opaque)
  }

  // Create ImageData from pixel data
  const imageData = new ImageData(pixelData, grid.cols, grid.rows, {
    colorSpace: "srgb",
  });

  // Convert ImageData to ImageBitmap
  return await createImageBitmap(imageData, {
    premultiplyAlpha: "premultiply",
  });
}
