import "./DbrdAreaTraffic.scss";

import * as d3 from "d3";
import reductio from "reductio";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import { AVIColorScales } from "../../chart-components/AVIColorScales";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { NumUtils } from "../../chart-components/ChartUtils/NumUtils";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { BarChartLinear } from "../../chart-components/Charts/BarChartLinear";
import { BarChartOrdinal } from "../../chart-components/Charts/BarChartOrdinal";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { TrackMapChart } from "../../chart-components/ChartsExt/TrackMapChart";
import GroupedNumberDisplayMax from "../../chart-components/GroupedNumberDisplayMax";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import withRouter from "../../hocs/withRouter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";
import { DrawPolygonInMap } from "../../ui-components/filter-view/FilterMap/DrawPolygonInMap";
import { EnterCoordinates } from "../../ui-components/filter-view/FilterMap/EnterCoordinates";
import { KyvLayerSwitcher } from "../../ui-components/filter-view/FilterMap/kyv-layer-switcher/KyvLayerSwitcher";
import { KYVGroupings } from "../../utils/KYVGroupings";
import { isArrayN } from "../../utils/array/isArrayN";
import { geoJSONFormat, wktFormat } from "../../utils/geomUtils";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { FilterToFromTime } from "../../filters/FilterToFromTime";

class DbrdAreaTrafficBase extends Component<any, any> {
  static dashboardRoute = "trafikkiomrade";

  static dashboardFilters(filter, setFilter) {
    return {
      helpMessage:
        "Trafikk i område genererer svært mye data. For områder med mye trafikk kan du ikke velge mer enn 1-2 måneder om gangen, for områder med mindre trafikk kan du ofte velge ut et år eller mer. Prøv deg frem. Gå tilbake og begrens utvalget hvis fremdriftsindikatoren blir stående å spinne på skjermen lenge. Tidspunkt i dataene er angitt som UTC.",
      controls: [
        <div key="flt_year_time_container">
          <FilterToFromTime
            key="flt_time"
            filter={filter}
            setFilter={setFilter}
            format="dd/MM/yyyy"
            maxTime={TimeUtils.getUtcDateDiff(-3)}
          />
          <EnterCoordinates
            key="flt-enter-coord"
            name="geom"
            filter={filter}
            setFilter={setFilter}
            outMode="geoJSON"
          />
          <DrawPolygonInMap
            name="geom"
            key="flt-draw-poly"
            filter={filter}
            setFilter={setFilter}
          />
        </div>,
      ],
    };
  }

  static dashboardSettings() {
    return {
      filterControls: [
        // DashboardConfig.filterControls.drawPolygon,
        // DashboardConfig.filterControls.municipality,
      ],
      selectableLayer: null,
    };
  }

  static dashboardValidation(filter) {
    // console.log(filter);
    if (isArrayN(filter.geom, 1, true) && filter.fromTime && filter.toTime) {
      return true;
    }
  }

  static propTypes = {
    location: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      chartData: null,
      geom: null,
    };

    this.reportProgress = this.reportProgress.bind(this);
  }

  componentDidMount() {
    const { geom, fromTime, toTime } = this.props.location.state;
    try {
      if (isArrayN(geom, 1, true) && fromTime && toTime) {
        var apiReqs = geom.map((geo) => {
          let wkt = wktFormat.writeFeature(geoJSONFormat.readFeature(geo));
          var payload = {
            Geom: wkt,
            StartTime: fromTime,
            EndTime: toTime,
          };
          return DataLoader.post(
            process.env.REACT_APP_DASHBOARD_WS_API + "/api/tracks/within-area",
            payload,
            this.reportProgress
          );
        });

        Promise.all(apiReqs)
          .then((responses) => {
            var rows: any[] = [];

            responses.forEach((response) => {
              response.data.forEach((row) => {
                rows.push(row);
              });
            });

            if (rows && rows.length) {
              this.setState({
                chartData: crossfilter(rows),
                geom: geom,
              });
            } else {
              this.setState({
                chartData: crossfilter([]),
                geom: geom,
              });
            }
          })
          .catch((error) => {
            console.warn(error);
            this.setState({
              chartData: crossfilter([]),
            });
          });
      } else {
        throw new Error("Missing selection parameters");
      }
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
        geom: null,
      });
    }
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  render() {
    const { chartData, geom, progressData } = this.state;
    const { fromTime, toTime, selectedMunicipality } =
      this.props.location.state;

    if (!chartData || chartData.size() === 0) {
      return <Loader chartData={chartData} progressData={progressData} />;
    }

    var exceptionReducer = (reductio() as any)
      .exception(function (d) {
        return d.mmsi;
      })
      .exceptionCount(true);

    var municipalityName = selectedMunicipality
      ? selectedMunicipality.label
      : "brukerdefinert område";
    var dashboardTitle = "Trafikk i område: " + municipalityName;

    var dimTracks = chartData.dimension(
      (d) => [d3.timeDay(new Date(d.starttime)), d.id, d.geometry],
      true
    );

    var dimCount = chartData.dimension((d) => (d.mmsi ? d.mmsi : "0"));

    var dimLength = chartData.dimension((d) => (d.length ? d.length : -1));
    var maxLength = dimLength.groupAll();

    var dimBreadth = chartData.dimension((d) => (d.breadth ? d.breadth : -1));
    var maxBreadth = dimBreadth.groupAll();

    var dimHeight = chartData.dimension((d) =>
      d.height !== 0 ? d.height : -1
    );
    var maxHeight = dimHeight.groupAll();

    // Create draught dimension and groups
    var dimDraught = chartData.dimension((d) =>
      d.draught ? Math.ceil(d.draught) : -1
    );
    var maxDraught = dimDraught.groupAll();

    var nmisSailedByDraught = dimDraught.group().reduceSum((d) => d.nmi);
    var shipsByDraught = dimDraught.group();
    exceptionReducer(shipsByDraught);

    var segmentsByDraught = dimDraught.group().reduceCount();

    // Create length group dimension and groups
    var dimLengthGroup = chartData.dimension((d) => {
      return KYVGroupings.getShipLengthGroup(d.length);
    });
    var shipLengthLabels = KYVGroupings.getShipLengthLabels();

    var nmisSailedByLengthGroup = dimLengthGroup
      .group()
      .reduceSum((d) => d.nmi);

    var shipsByLengthGroup = dimLengthGroup.group();
    exceptionReducer(shipsByLengthGroup);
    var segmentsByLengthGroup = dimLengthGroup.group().reduceCount();

    // Ship category dimension and groups
    var dimShipCategory = chartData.dimension((d) =>
      d.sgroup2 ? d.sgroup2.trim() : "Ukjent"
    );
    var nmisSailedByShipCategory = dimShipCategory
      .group()
      .reduceSum((d) => d.nmi);
    var shipsByShipCategory = dimShipCategory.group();
    exceptionReducer(shipsByShipCategory);
    var segmentsByShipCategory = dimShipCategory.group().reduceCount();

    // Ship type dimension and groups
    var dimShipType = chartData.dimension((d) =>
      d.shiptypenor2 ? d.shiptypenor2.trim() : "Ukjent"
    );
    var shipsByShipType = dimShipType.group();
    exceptionReducer(shipsByShipType);

    // Hour of day dimension and groups
    var dimHourOfDay = chartData.dimension((d) =>
      new Date(d.endtime).getHours()
    );
    var nmisSailedByHourOfDay = dimHourOfDay.group().reduceSum((d) => d.nmi);

    var shipsByHourOfDay = dimHourOfDay.group();
    exceptionReducer(shipsByHourOfDay);

    var segmentsByHourOfDay = dimHourOfDay.group().reduceCount();

    var dimId = chartData.dimension((d) => d.id);

    return (
      <div className="AppView">
        <AVIDashboard
          title={dashboardTitle}
          desc={`Trafikk i tidsrommet fra ${TimeUtils.toCompactTimestring(
            new Date(fromTime)
          )} til ${TimeUtils.toCompactTimestring(
            new Date(toTime)
          )}. Alle tidspunkt er angitt i UTC.`}
          cfilter={chartData}
          filter={this.props.location.state}
          type="sum"
          keyName="nmi"
          group={dimCount.groupAll()}
          units="nautiske mil"
          useFlex
        >
          <GroupedNumberDisplayMax
            chartTitle="Skipsdimensjoner"
            height={0.5}
            shipsByDraft={maxDraught}
            maxLength={maxLength}
            maxHeight={maxHeight}
            maxBreadth={maxBreadth}
            useFlex
          />
          <TrackMapChart
            chartTitle="Seilas innen område (viser inntil 5 000)"
            dimension={dimTracks}
            intersectGeom={geom}
            chartData={chartData}
            colorScale={AVIColorScales.shipCategory}
            categoryProperty="sgroup2"
            maxFeatures={5000}
            height={3.5}
            width={4}
            useFlex
            allowFullscreen
          >
            <KyvLayerSwitcher top="10px" right="10px" />
          </TrackMapChart>
          <AVIRow>
            <BarChartOrdinal
              chartTitle="Seilt distanse etter lengdegruppe (Nautiske mil)"
              dimension={dimLengthGroup}
              group={nmisSailedByLengthGroup}
              ordering={(d) => shipLengthLabels.indexOf(d.key)}
              width={1.3}
              height={1}
              filterPrefix="Lengdegruppe"
              xAxisLabel="Lengdegruppe"
              yAxisLabel="Seilt distanse (nmi)"
              margins={{ left: 40 }}
              useFlex
            />
            <BarChartOrdinal
              chartTitle="Antall unike skip etter lengdegruppe"
              dimension={dimLengthGroup}
              group={shipsByLengthGroup}
              ordering={(d) => shipLengthLabels.indexOf(d.key)}
              width={1.3}
              height={1}
              valueAccessor={(d) => d.value.exceptionCount}
              filterPrefix="Lengdegruppe"
              xAxisLabel="Lengdegruppe"
              yAxisLabel="Antall skip"
              useFlex
            />
            {/* <BarChartOrdinal
              chartTitle="Antall linjesegmenter etter lengdegruppe"
              dimension={dimLengthGroup}
              group={segmentsByLengthGroup}
              ordering={(d) => shipLengthLabels.indexOf(d.key)}
              width={1.3}
              height={1}
              filterPrefix="Lengdegruppe"
              xAxisLabel="Lengdegruppe"
              yAxisLabel="Antall linjesegmenter"
              useFlex
            /> */}
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              chartTitle="Seilt distanse etter skipts dyptgående (Nautiske mil)"
              dimension={dimDraught}
              group={nmisSailedByDraught}
              width={1}
              height={1}
              filterPrefix="Dyptgående"
              xAxisLabel="Skipets dyptgående (-1 = ukjent)"
              yAxisLabel="Seilt distanse (nmi)"
              useFlex
            />
            <BarChartOrdinal
              chartTitle="Antall unike skip etter skipts dyptgående"
              dimension={dimDraught}
              group={shipsByDraught}
              valueAccessor={(d) => d.value.exceptionCount}
              width={1}
              height={1}
              filterPrefix="Dyptgående"
              xAxisLabel="Skipets dyptgående (-1 = ukjent)"
              yAxisLabel="Antall unike skip"
              useFlex
            />
            {/* <BarChartOrdinal
              chartTitle="Antall linjesegmenter etter skipts dyptgående"
              dimension={dimDraught}
              group={segmentsByDraught}
              width={1}
              height={1}
              filterPrefix="Dyptgående"
              xAxisLabel="Skipets dyptgående (-1 = ukjent)"
              yAxisLabel="Antall linjesegmenter"
              useFlex
            /> */}
          </AVIRow>
          <AVIRow>
            <RowChart
              chartTitle="Seilt distanse etter skipstype (Nautiske mil)"
              width={1}
              height={1}
              colors={AVIColorScales.shipCategory}
              gap={1}
              dimension={dimShipCategory}
              group={nmisSailedByShipCategory}
              filterPrefix="Skipskategori"
              valueAccessor={(d) => Math.round(d.value)}
              useFlex
            />
            <RowChart
              chartTitle="Antall unike skip etter skipskategori"
              width={1}
              height={1}
              colors={AVIColorScales.shipCategory}
              gap={1}
              dimension={dimShipCategory}
              group={shipsByShipCategory}
              filterPrefix="Skipskategori"
              valueAccessor={(d) => Math.round(d.value.exceptionCount)}
              ordering={(d) => -d.value.exceptionCount}
              useFlex
            />
            {/* <RowChart
              chartTitle="Antall linjesegmenter etter skipskategori"
              width={1}
              height={1}
              colors={AVIColorScales.shipCategory}
              gap={1}
              dimension={dimShipCategory}
              group={segmentsByShipCategory}
              filterPrefix="Skipskategori"
              ordering={(d) => -d.value.exceptionCount}
              useFlex
            /> */}
          </AVIRow>
          <AVIRow>
            <RowChart
              chartTitle="Antall unike skip etter skipstype/Statcode5 (viser inntil de 15 største)"
              width={4}
              height={1.5}
              gap={1}
              dimension={dimShipType}
              group={GroupUtils.RemoveEmptyBinsTopN(
                shipsByShipType,
                15,
                (a, b) =>
                  a.value.exceptionCount > b.value.exceptionCount
                    ? -1
                    : b.value.exceptionCount > a.value.exceptionCount
                    ? 1
                    : 0,
                (d) => d.value.exceptionCount
              )}
              filterPrefix="Skipstype"
              valueAccessor={(d) => Math.round(d.value.exceptionCount)}
              ordering={(d) => -d.value.exceptionCount}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartLinear
              chartTitle="Seilt distanse etter time i døgnet (Nautiske mil)"
              dimension={dimHourOfDay}
              group={nmisSailedByHourOfDay}
              width={1}
              height={1}
              filterPrefix="Time i døgnet"
              xAxisLabel="Time i døgnet (UTC)"
              yAxisLabel="Seilt distanse (nmi)"
              useFlex
            />
            <BarChartLinear
              chartTitle="Antall unike skip etter time i døgnet"
              dimension={dimHourOfDay}
              group={shipsByHourOfDay}
              width={1}
              height={1}
              filterPrefix="Time i døgnet"
              valueAccessor={(d) => Math.round(d.value.exceptionCount)}
              xAxisLabel="Time i døgnet (UTC)"
              yAxisLabel="Antall skip"
              useFlex
            />
            {/* <BarChartLinear
              chartTitle="Antall linjesegmenter etter time i døgnet"
              dimension={dimHourOfDay}
              group={segmentsByHourOfDay}
              width={1}
              height={1}
              filterPrefix="Time i døgnet"
              xAxisLabel="Time i døgnet (UTC)"
              yAxisLabel="Antall linjesegmenter"
              useFlex
            /> */}
          </AVIRow>
          <DataTable
            chartTitle="Seilas med skipsinformasjon"
            dimension={dimId}
            useFlex
            sortBy={(d) =>
              d.mmsi +
              "-" +
              TimeUtils.toTimestamp(d.starttime) +
              "-" +
              TimeUtils.toTimestamp(d.endtime)
            }
            width={4}
            size={Infinity}
            columns={[
              {
                label: "MMSI#",
                format: (d) => d.mmsi || 0,
              },
              {
                label: "Kallesignal",
                format: (d) => d.callsign || "",
              },
              {
                label: "Skipsnavn",
                format: (d) => d.shipname || "",
              },
              {
                label: "Tidspunkt",
                format: (d) =>
                  TimeUtils.toCompactTimestring(new Date(d.endtime)),
                value: (d) =>
                  TimeUtils.toCompactTimestring(new Date(d.endtime)),
              },
              {
                label: "Skipskategori",
                title: "Skipskategori",
                format: (d) => d.sgroup2 || "",
              },
              {
                label: "Skipstype",
                title: "Skipstype (Statcode5)",
                format: (d) => d.shiptypenor2 || "",
              },
              {
                label: "BT",
                title: "Bruttotonnasje",
                format: (d) => NumUtils.integer(d.grosstonnage),
                value: (d) => d.grosstonnage,
              },
              {
                label: "DWT",
                title: "Dødvektstonnasje",
                format: (d) => NumUtils.integer(d.dwt),
                value: (d) => d.dwt,
              },
              {
                label: "L",
                title: "Lengde (Length Over All)",
                format: (d) => NumUtils.decimal2((0 + d.length).toFixed(2)),
                value: (d) => d.length,
              },
              {
                label: "B",
                title: "Skipsbredde",
                format: (d) => NumUtils.decimal2(d.breadth),
                value: (d) => d.breadth,
              },
              {
                label: "H",
                title: "Skipshøyde (air draught)",
                format: (d) => NumUtils.decimal2(d.height),
                value: (d) => d.height,
              },
              {
                label: "D",
                title: "Dyptgående",
                format: (d) => NumUtils.decimal2(d.draught),
                value: (d) => d.draught,
              },
              {
                label: "Utseilt distanse",
                format: (d) => NumUtils.decimal2(d.nmi),
                value: (d) => d.nmi,
              },
              {
                label: "Land",
                format: (d) => d.countrynamenor || "",
              },
            ]}
          />
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdAreaTraffic = withRouter(DbrdAreaTrafficBase);

export default DbrdAreaTraffic;
