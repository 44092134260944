// import * as d3 from 'd3';

export class KYVGroupings {
  static shipLengthGroupDef = [
    {
      min: 0,
      max: 24,
      label: "<24 m",
    },
    {
      min: 24,
      max: 50,
      label: "24-50 m",
    },
    {
      min: 50,
      max: 70,
      label: "50-70 m",
    },
    {
      min: 70,
      max: 100,
      label: "70-100 m",
    },
    {
      min: 100,
      max: 150,
      label: "100-150 m",
    },
    {
      min: 150,
      max: 225,
      label: "150-225 m",
    },
    {
      min: 225,
      max: Infinity,
      label: "> 225 m",
    },
    {
      min: -1,
      max: 0,
      label: "Ukjent",
    },
  ];

  static grossTonnageGroups = [
    { min: -Infinity, max: 1000, label: "Opp til 1000 BT" },
    { min: 1000, max: 2000, label: "1000 - 2000 BT" },
    { min: 2000, max: 4000, label: "2000 - 4000 BT" },
    { min: 4000, max: 8000, label: "4000 - 8000 BT" },
    { min: 8000, max: 12000, label: "8000 - 12000 BT" },
    { min: 12000, max: 20000, label: "12000 - 20000 BT" },
    { min: 20000, max: 30000, label: "20000 - 30000 BT" },
    { min: 30000, max: 50000, label: "30000 - 50000 BT" },
    { min: 50000, max: 100000, label: "50000 - 100000 BT" },
    { min: 100000, max: Infinity, label: "Over 100000 BT" },
  ];

  static getGroup(groupDefs, noData) {
    // Set default
    noData = !noData ? "N/A" : noData;

    return (val) => {
      if (val === null || val === undefined) {
        return noData;
      }

      for (var i = 0; i < groupDefs.length; i++) {
        var currentGroup = groupDefs[i];
        if (val >= currentGroup.min && val < currentGroup.max) {
          return currentGroup.label;
        }
      }

      return noData;
    };
  }

  static getShipLengthGroup(length) {
    return this.getGroup(this.shipLengthGroupDef, "Ukjent")(length);
  }

  static getShipLengthLabels() {
    return this.shipLengthGroupDef.map((d) => {
      return d.label;
    });
  }

  static getGrossTonnageGroup(gt) {
    return this.getGroup(this.grossTonnageGroups, "Ukjent")(gt);
  }

  static getGrossTonnageGroupLabel() {
    return this.grossTonnageGroups.map((d) => {
      return d.label;
    });
  }

  static CommonLengthGroups = [
    "Under 30 meter",
    "30-49 meter",
    "50-69 meter",
    "70-89 meter",
    "90-109 meter",
    "110-129 meter",
    "130-149 meter",
    "150-169 meter",
    "170-189 meter",
    "190-209 meter",
    "210-250 meter",
    "Over 250 meter",
  ];

  static CommonTonnageGroups = [
    "Opp til 1000 BT",
    "1001 - 2000 BT",
    "2001 - 4000 BT",
    "4001 - 8000 BT",
    "8001 - 12000 BT",
    "12001 - 20000 BT",
    "20001 - 30000 BT",
    "30001 - 50000 BT",
    "50001 - 100000 BT",
    "Over 100000 BT",
  ];

  static CommonDwtTonnageGrops = [
    "Opp til 1000 DWT",
    "1001-2000 DWT",
    "2001-3000 DWT",
    "3001-5000 DWT",
    "5001-10000 DWT",
    "Over 10000 DWT",
  ];
}
