import { Button, Card, CardBody, CardFooter, CardImg, CardText, CardTitle, Col, Image } from "react-bootstrap";
import { KyvMetadataRecord } from "../../utils/kystinfo";
import React from "react";

export type DataCatalogRowProps = {
    rec: KyvMetadataRecord
}

export function DataCatalogRow({ rec }: DataCatalogRowProps) {
    return <Col xs={12}>
        <div className="d-flex gap-3 w-100 p-3 bg-white text-dark mb-3 align-items-center" >
            <Image src="/dashboard.png" style={{ height: "10vh", objectFit: "cover" }} />
            <div>
                <div className="text-start text-black fs-5">
                    {rec.title}
                </div>
                <div className="kyvdvh-truncate-2l text-body-secondary" dangerouslySetInnerHTML={{ __html: rec.abstract }}></div>
            </div>
            <Button variant="outline-dark" className="ms-auto small p-1 border-0"><i className="bi bi-link" /></Button>
            <div className="small text-body-secondary">
                {rec.resource_type}
            </div>
        </div>
    </Col>
}