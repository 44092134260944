import * as turf from '@turf/turf';
import crossfilter from "crossfilter2";
import { Feature, FeatureCollection, Point } from "geojson";
import GeoJSON, { GeoJSONFeatureCollection } from "ol/format/GeoJSON";
import ImageLayer from 'ol/layer/Image';
import WebGLPointsLayer from 'ol/layer/WebGLPoints.js';
import { fromLonLat } from 'ol/proj';
import Static from 'ol/source/ImageStatic';
import VectorSource from "ol/source/Vector";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMap } from "../../context/MapProvider";
import { AisPos } from '../../types/Ais';
import { burnMaxIntoGrid } from '../../utils/grid/burnMaxIntoGrid';
import { waveColorScale } from '../../utils/grid/createImageBitmapFromGridData';
import { gridToCanvas } from "../../utils/grid/gridToCanvas";
import { interpolateGridValues } from '../../utils/grid/interpolateGridValues';
import { makeGrid } from '../../utils/grid/makeGrid';
import { WaveCalcParams } from '../../views/DbrdWaveImpact/DbrdWaveImpact';

type OlMapWaveLayerProps = {
    cf: crossfilter.Crossfilter<AisPos>
    waveCalcParams: WaveCalcParams
    setWaveHeight: (waveHeight: number) => void
    geoJson: GeoJSONFeatureCollection
}

interface Ship {
    width: number;    // width of the ship (in meters)
    length: number;   // length of the ship (in meters)
    speed: number;    // speed of the ship (in meters per second)
}

function degreesToRadians(degrees: number): number {
    return degrees * (Math.PI / 180);
}

export function OlMapWaveLayer({ cf, waveCalcParams, setWaveHeight, geoJson }: OlMapWaveLayerProps) {

    const { map } = useMap();
    
    const timeoutHandle = useRef<NodeJS.Timeout>();

    const { speed, length, breadth, empiricalConstant, falloff } = waveCalcParams;

    const [minWaveHeight,] = useState<number>(0);
    const [maxWaveHeight,] = useState<number>(1);

    // const colorScale = useMemo(() => d3.scaleSequential().domain([minWaveHeight, maxWaveHeight]).interpolator(d3.interpolateRdYlGn), [minWaveHeight, maxWaveHeight]);

    const styleFunction = useCallback(() => {
        return {
            'circle-radius': [
                'interpolate',
                ['linear'],
                ['get', 'waveHeight'],
                minWaveHeight,
                1,
                maxWaveHeight,
                15
            ],
            'circle-fill-color': [
                'interpolate',
                ['linear'],
                ['get', 'waveHeight'],
                0,
                '#00CC00',
                0.25,
                '#FF6600',
                5,
                '#FF0000'
            ],
            'circle-rotate-with-view': false,
            'circle-displacement': [0, 0],
            'circle-opacity': 0.92,
        }
    }, [minWaveHeight, maxWaveHeight]);

    // Simplified formula to calculate the initial wave height based on ship dimensions and speed
    /**
     * Function to calculate the height of the wave generated by a ship.
     * 
     * @param ship - Speed of the ship in knots.
     * @returns Wave height in meters.
     */
    const calculateWaveHeight = useCallback((ship: Ship): number => {
        // Convert speed from knots to meters per second (1 knot = 0.51444 m/s)
        const speedInMetersPerSecond = ship.speed * 0.51444;

        // Calculate the Froude number (Fr = speed / sqrt(g * L))
        const g = 9.81; // Acceleration due to gravity in m/s²
        const froudeNumber = speedInMetersPerSecond / Math.sqrt(g * ship.length);

        // Wave height approximation formula
        const waveHeight = empiricalConstant * Math.pow(froudeNumber, 2) * (ship.width / ship.length) * ship.length;

        // Return the calculated wave height
        return waveHeight;
    }, [empiricalConstant]);

    // Function to calculate wave height at a distance perpendicular to the ship's direction
    const calculateWaveHeightAtDistance = useCallback((initialWaveHeight: number, distance: number): number => {
        // Example inverse square law for wave height depletion
        return initialWaveHeight / Math.pow(distance, falloff);
    }, [falloff]);

    const calculatePerpendicularPoints = useCallback((
        startPoint: AisPos, maxDistance: number, interval: number
    ): [Feature[], number, number] => {
        const points: Feature[] = [];
        let minWaveHeight = Infinity;

        const waveHeightAtOrigin = calculateWaveHeight({ width: breadth, length: length, speed: speed || startPoint.sog });

        const originPoint = turf.point([startPoint.lon, startPoint.lat, 0], { waveHeight: waveHeightAtOrigin, distance: 0, type: "origin" });

        points.push(originPoint);

        for (let d = interval; d <= maxDistance; d += interval) {

            const bearing = degreesToRadians(startPoint.cog);

            const waveHeightAtDistance = calculateWaveHeightAtDistance(waveHeightAtOrigin, d);
            if (waveHeightAtDistance < minWaveHeight) {
                minWaveHeight = waveHeightAtDistance;
            }

            const angleRight = bearing + Math.PI / 2;  // Perpendicular direction to the right
            const angleLeft = bearing - Math.PI / 2;  // Perpendicular direction to the left

            const pointRight = turf.destination(turf.point([startPoint.lon, startPoint.lat]), d, angleRight * (180 / Math.PI), { units: 'meters' });
            pointRight.properties = { waveHeight: waveHeightAtDistance, distance: d, type: "right" };
            points.push(pointRight);

            const pointLeft = turf.destination(turf.point([startPoint.lon, startPoint.lat]), d, angleLeft * (180 / Math.PI), { units: 'meters' });
            pointLeft.properties = { waveHeight: waveHeightAtDistance, distance: d, type: "left" };
            points.push(pointLeft);
        }

        return [points, minWaveHeight, waveHeightAtOrigin];

    }, [breadth, calculateWaveHeight, calculateWaveHeightAtDistance, length, speed]);


    const [vectorSource, webGLLayer] = useMemo(() => {
        const _vectorSource = new VectorSource<any>({
            format: new GeoJSON(),
            wrapX: true
        });
        const _webGLLayer = new WebGLPointsLayer({
            source: _vectorSource,
            properties: { "title": "AIS points" },
            zIndex: 110,
            visible: true,
            opacity: 0.75,
            style: styleFunction()
        })
        return [_vectorSource, _webGLLayer];
    }, [styleFunction]);

    const imgLyr = useMemo(() => {
        const _imgLyr = new ImageLayer({
            properties: {
                title: "Bølgepåvirkningsraster"
            },
            visible: true,
            zIndex: 109,
        });
        return _imgLyr;
    }, []);

    useEffect(() => {
        map.addLayer(webGLLayer);
        map.addLayer(imgLyr);

        return () => {
            map.removeLayer(webGLLayer);
            map.removeLayer(imgLyr);
        }
    }, [imgLyr, webGLLayer, map]);

    useEffect(() => {

        async function loadData() {

            vectorSource.clear();

            const fc: FeatureCollection<Point, any> = {
                type: "FeatureCollection",
                features: []
            };

            let min = Infinity;
            let max = -Infinity;
            cf.allFiltered().forEach(p => {
                const [whs, _min, _max] = calculatePerpendicularPoints(p, 500, 25);
                if (_min < min) {
                    min = _min;
                }
                if (_max > max) {
                    max = _max;
                }
                fc.features.push(...whs as Feature<Point>[]);
            })

            const pts = new Float32Array(3 * fc.features.length);

            let i = 0;
            let minx = Infinity;
            let miny = Infinity;
            let maxx = -Infinity;
            let maxy = -Infinity;

            fc.features.forEach((f) => {
                const [x, y] = fromLonLat(f.geometry.coordinates);
                pts[i] = x;
                pts[i + 1] = y;
                pts[i + 2] = (f.properties["waveHeight"]);
                if (x < minx) minx = x;
                if (x > maxx) maxx = x;
                if (y < miny) miny = y;
                if (y > maxy) maxy = y;
                i += 3;
            });

            let grid = makeGrid(minx, miny, maxx, maxy, 100);

            for (let i = 0; i < pts.length; i += 3) {
                burnMaxIntoGrid(pts[i], pts[i + 1], pts[i + 2], grid);
            }

            grid = interpolateGridValues(grid, 2, 2)

            const canvas = await gridToCanvas(grid, waveColorScale);
            // Create a TileLayer using the DataTile source

            imgLyr.setSource(new Static({
                url: canvas.toDataURL(), // Use the canvas data as the image source
                imageExtent: [grid.extent.minx, grid.extent.miny, grid.extent.maxx, grid.extent.maxy],
                interpolate: false
            }));

            vectorSource.clear(true);

            vectorSource.addFeatures(new GeoJSON().readFeatures(fc, {
                featureProjection: 'EPSG:3857',
                dataProjection: "EPSG:4326"
            }));
        }

        function onMove(ev) {
            map.forEachFeatureAtPixel(ev.pixel, function (feature) {
                setWaveHeight(Number(Number(feature.get("waveHeight")).toFixed(2)));
                return true;
            });
        }

        map.on("pointermove", onMove)

        if (timeoutHandle.current) {
            clearTimeout(timeoutHandle.current);
        }

        timeoutHandle.current = setTimeout(() => loadData(), 250);

        return () => {
            map.un("pointermove", onMove);

            if (timeoutHandle.current) {
                clearTimeout(timeoutHandle.current);
            }
        }
    }, [calculatePerpendicularPoints, cf, imgLyr, webGLLayer, map, vectorSource, setWaveHeight]);
    
    return null;
}