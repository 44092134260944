import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DashboardList } from "../config/dashboards";
import CoreLayout from "../layouts/CoreLayout";
import MapLayout from "../layouts/MapLayout";
import ModalLayout from "../layouts/ModalLayout";
import ArticleView from "../views/ArticleView/ArticleView";
import DbrdAnchorage from "../views/DbrdAnchorage/DbrdAnchorage";
import ExclusionEditorView from "../views/ExclusionEditorView/ExclusionEditorView";
import GdprView from "../views/GdprView/GdprView";
import HelpView from "../views/HelpView/HelpView";
import IndexView from "../views/IndexView/IndexView";
import LoginView from "../views/LoginView/LoginView";
import { TestView } from "../views/TestView";
import KyvAnalytics from "./KyvAnalytics";
import PrivateRoute from "./PrivateRoute";

import { FilterView } from "../views/FilterView/FilterView";
import { DbrdRedirector } from "./DbrdRedirector";
import DashboardLayout from "../layouts/DashboardLayout";
import { CruiseMappingView } from "../views/CruiseMappingView/CruiseMappingView";
import { DataCatalogView } from "../views/DataCatalogView/DataCatalogView";
import { DataCatalogDetail } from "../views/DataCatalogView/DataCatalogDatasetDetail";

export function AppRouter(props) {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <React.Fragment>
        <Routes>
          <Route
            exact
            path="/v2"
            element={
              <Routes>
                <Route
                  exact
                  path=":resourceType/:metadataId"
                  element={
                    <CoreLayout>
                      <DataCatalogDetail />
                    </CoreLayout>
                  }
                ></Route>
                <Route
                  exact
                  path="*"
                  element={
                    <CoreLayout>
                      <DataCatalogView />
                    </CoreLayout>
                  }
                ></Route>
              </Routes>
            }
          ></Route>
          <Route exact path="/test" element={<TestView />}></Route>
          <Route
            exact
            path="/logginn"
            element={
              <CoreLayout>
                <LoginView />
              </CoreLayout>
            }
          ></Route>
          <Route
            exact
            path="/gdpr"
            element={
              <ModalLayout>
                <GdprView />
              </ModalLayout>
            }
          ></Route>
          <Route
            exact
            path="/artikkel/:id"
            element={
              <CoreLayout>
                <ArticleView />
              </CoreLayout>
            }
          ></Route>
          <Route
            exact
            path="/"
            element={
              <CoreLayout>
                <IndexView />
              </CoreLayout>
            }
          ></Route>
          <Route
            exact
            path="/tallogstatistikk"
            element={
              <PrivateRoute>
                <MapLayout>
                  <FilterView />
                </MapLayout>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/dev/test"
            element={
              <ModalLayout>
                <TestView />)
              </ModalLayout>
            }
          />
          <Route
            exact
            path="/direct/anchorage/:anchoragesysid"
            element={
              <ModalLayout>
                <DbrdAnchorage />
              </ModalLayout>
            }
          />
          <Route
            exact
            path="/direct/anchorage/dok_status/:dok_status/dok_id/:dok_id"
            element={
              <ModalLayout>
                <DbrdAnchorage />
              </ModalLayout>
            }
          />
          <Route
            path="/tallogstatistikk/:dashboard/filter"
            element={
              <PrivateRoute>
                <MapLayout>
                  <FilterView />
                </MapLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/*"
            element={
              <Routes>
                <Route
                  exact
                  path="exclusion"
                  element={
                    <PrivateRoute>
                      <ModalLayout>
                        <ExclusionEditorView />
                      </ModalLayout>
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="cruise-mapping"
                  element={
                    <PrivateRoute>
                      <ModalLayout>
                        <CruiseMappingView />
                      </ModalLayout>
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="hjelp"
                  element={
                    <PrivateRoute>
                      <ModalLayout>
                        <HelpView />
                      </ModalLayout>
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="link/:shortCode"
                  element={<DbrdRedirector />}
                />
                {Object.keys(DashboardList).map((dashboard, routeIdx) => {
                  return (
                    <Route
                      key={`route-${dashboard}`}
                      exact
                      path={`tallogstatistikk/${dashboard}/dashboard`}
                      element={
                        <PrivateRoute>
                          <DashboardLayout>
                            <React.Suspense fallback={<div>...</div>}>
                              {React.createElement(DashboardList[dashboard][0])}
                            </React.Suspense>
                          </DashboardLayout>
                        </PrivateRoute>
                      }
                    />
                  );
                })}
              </Routes>
            }
          ></Route>
        </Routes>
        <KyvAnalytics />
      </React.Fragment>
    </BrowserRouter>
  );
}

export default AppRouter;
