import "./DataCatalogView.scss";

import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";


export function DataCatalogDetail() {

  const { resourceType, metadataId } = useParams();

  useEffect(() => {

    return () => {
    }
  }, []);

  return (
    <>
      {/* <section role="heading" aria-level={2}>
        <Container className="px-5 mb-5 mx-auto text-center">
          <div className="display-1 mb-3">Datakatalog</div>
          <div className="display-3">Hvordan kan vi hjelpe deg?</div>
          </Container>
          </section> */}
      <section role="article">
        <Container className="px-5 mb-5">
          <div className="display-1 mb-3">Title</div>
          <div className="d-flex">
            Abstract
          </div>
        </Container>
      </section>
    </>
  );

}