import { Dimension, NaturallyOrderedValue } from 'crossfilter2';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Cell, LabelList, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CrossfilterGroup } from '../../types/Crossfilter';
import { AVIColorScales } from '../AVIColorScales';
import AVIChartTile from '../Layout/AVIChartTile';
import { useAVIDashboardContext } from '../Layout/AVIDashboardDimContext';

interface Margin {
    top?: number,
    right?: number,
    left?: number,
    bottom?: number,
}

const CONST_DEFAULT_MARGIN = {
    top: 5,
    right: 30,
    left: 180,
    bottom: 50,
}

type RowChart2Props<T = any> = {
    chartTitle: string,
    width: number,
    height: number,
    group: CrossfilterGroup,
    dim: Dimension<T, NaturallyOrderedValue>,
    xAxisLabel?: string
    keyAccessor?: (r: T) => NaturallyOrderedValue,
    valueAccessor?: (t: T) => NaturallyOrderedValue,
    colorAccessor?: (v: T) => string,
    sortFunction?: (a: T, b: T) => number,
    margin?: Margin
}

export function RowChart2<T = any>({
    group,
    dim,
    chartTitle,
    width,
    height,
    sortFunction,
    margin,
    xAxisLabel = "",
    keyAccessor = (t: T) => t["key"],
    valueAccessor = (t: T) => t["value"],
    colorAccessor = AVIColorScales.colorScaleKyv2024Mono,
}: RowChart2Props) {

    const [updateCount, setUpdateCount] = useState<number>(0);

    const getChart = useCallback(() => {

    }, []);

    const { cfilter } = useAVIDashboardContext();

    const activeMargin = useMemo(() => {
        if (margin) {
            return Object.assign({}, CONST_DEFAULT_MARGIN, margin)
        } else {
            return CONST_DEFAULT_MARGIN;
        }
    }, [margin]);
    
    const data = useMemo(() => {
        if (updateCount === -1) return;
        const dataCopy = [...group.all()];        
        if (typeof sortFunction === "function") {
            dataCopy.sort(sortFunction);
        }
        return dataCopy;
    }, [group, sortFunction, updateCount]);

    useEffect(() => {
        if (!cfilter) return;
        const removeUpdateListener = cfilter.onChange(() => {
            setUpdateCount(c => c + 1);
        }) as unknown as Function

        return () => {
            if (typeof removeUpdateListener === "function") {
                removeUpdateListener();
            }
        }
    }, [cfilter]);

    
    if (!data) return;

    return (
        <AVIChartTile
            title={chartTitle}
            getChart={getChart}
            width={width}
            height={height}
            useFlex={true}
        >
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    layout='vertical'
                    width={500}
                    height={300}
                    data={data as any}
                    margin={activeMargin}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" label={xAxisLabel} tick={{ fontSize: 11, fontFamily: 'museo-sans, sans-serif' }} />
                    <YAxis type="category" dataKey="key" tick={{ fontSize: 11, fontFamily: 'museo-sans, sans-serif' }} />
                    <Tooltip />
                    {/* <Brush height={5}/> */}
                    <Bar dataKey="value" color="red" fill="#8884d8" activeBar={<Rectangle fill="#C2A45E"/> }>
                        {
                            data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colorAccessor(entry.value)} />
                            ))
                        }
                        <LabelList dataKey="value" fontFamily='museo-sans' fontSize={11} position='right' fill="gray" />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </AVIChartTile>)
}